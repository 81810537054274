import { useState, useEffect } from 'react';
import { isVerified, validateLocation } from 'services/geolocation/geolocation';

export enum LocationVerificationStatus {
  Pending = 'pending',
  Verified = 'verified',
  Invalid = 'invalid',
  MissingLocationData = 'missing',
}

type locationVerificationResponse = {
  isLocationVerified: boolean;
};

export function useLocationVerification(isGeofencingActivated: boolean, slugId: string): LocationVerificationStatus {
  const initialStatus = isGeofencingActivated
    ? LocationVerificationStatus.Pending
    : LocationVerificationStatus.Verified;
  const [isLocationVerified, setIsLocationVerified] = useState<LocationVerificationStatus>(initialStatus);

  // Using async in useEffect
  // https://github.com/facebook/react/issues/14326
  useEffect(() => {
    let didCancel = false;

    // If geofencing is not activated or slugId is not available, return early
    if (!(isGeofencingActivated && slugId) || didCancel) {
      return () => {
        didCancel = true;
      };
    }

    // Failed to obtain location data
    function errorGettingLocation() {
      setIsLocationVerified(LocationVerificationStatus.MissingLocationData);
    }

    // Success in obtaining location data from the browser
    // Now validate location on the server
    function successGettingLocation(position: { coords: { latitude: number; longitude: number } }) {
      const locationData = {
        locationData: { lat: position.coords.latitude, lng: position.coords.longitude },
      };
      validateLocation(slugId, locationData)
        .then((response) => {
          const verificationStatus = (response.data as locationVerificationResponse).isLocationVerified;
          setIsLocationVerified(
            verificationStatus ? LocationVerificationStatus.Verified : LocationVerificationStatus.Invalid,
          );
        })
        .catch(() => {});
    }

    // validates
    // 1. check if already validated
    // 2. if not validated, get location from the browser and validate
    async function validate() {
      const verification = await isVerified(slugId);
      const verificationStatus: boolean = (verification.data as locationVerificationResponse).isLocationVerified;
      if (verificationStatus) {
        setIsLocationVerified(LocationVerificationStatus.Verified);
        return;
      }

      navigator.geolocation.getCurrentPosition(successGettingLocation, errorGettingLocation);
    }

    // invoke validation
    validate().catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Location validation failed:', error);
    });

    return () => {
      didCancel = true;
    };
  }, [slugId, isGeofencingActivated]);

  return isLocationVerified;
}
