/**
 * Combines all questions in a single array (standard questions, QQ, demographics, personal data)
 */

import { isQuestion, INCLUDE_QQ, EXCLUDE_FACTS } from '../containers/questions/QuestionConstants';

export function getTotalNrQuestions(questions) {
  return questions.filter((item) => isQuestion(item.questionType, INCLUDE_QQ, EXCLUDE_FACTS)).length;
}

function insertQuestion(questions, questionWrapper, isConditionalPoll) {
  if (questionWrapper && !questionWrapper.isSubmitted) {
    switch (questionWrapper.positionInPoll) {
      case 'first':
        return [questionWrapper.question, ...questions];
      case 'last':
        return [...questions, questionWrapper.question];
      case 'middle':
        // inserting in middle would potentially mess up conditional logic
        if (isConditionalPoll) {
          return [questionWrapper.question, ...questions];
        }
        // modify questions to insert new question in the middle
        questions.splice(Math.floor(questions.length / 2), 0, questionWrapper.question);
        return questions;
      // if wrong definition, return at first position
      default:
        return [questionWrapper.question, ...questions];
    }
  }
  // there is no question to insert
  return questions;
}

export function getCombinedQuestions(questions, questionsToAdd, isConditionalPoll) {
  const completeQuestions = questionsToAdd.reduce(
    (intermediatyQuestionArray, nextQuestion) =>
      insertQuestion(intermediatyQuestionArray, nextQuestion, isConditionalPoll),
    questions,
  );

  if (isConditionalPoll) {
    return completeQuestions;
  }
  return completeQuestions.filter((question) => !question.isSubmitted);
}
