import WrongLocation from 'components/location/WrongLocation';
import AccessDenied from 'components/location/AccessDenied';
import { LocationVerificationStatus } from './useLocationVerification';

type LocationProps = {
  isLocationVerified: LocationVerificationStatus;
  locationName: string | undefined;
  clazz: string;
};

export function LocationValidation({ isLocationVerified, locationName, clazz }: LocationProps) {
  const showLocationValidation = [
    LocationVerificationStatus.Invalid,
    LocationVerificationStatus.MissingLocationData,
  ].includes(isLocationVerified);

  if (!showLocationValidation) {
    return null;
  }

  return (
    <div className={clazz}>
      <main className='l-section-main c-section-main' id='appContent'>
        <div className='l-container'>
          <div className='c-modal-poll-container'>
            <div className='c-modal-poll'>
              {isLocationVerified === LocationVerificationStatus.Invalid ? (
                <WrongLocation closePoll={() => {}} location={locationName} />
              ) : null}
              {isLocationVerified === LocationVerificationStatus.MissingLocationData ? (
                <AccessDenied closePoll={() => {}} />
              ) : null}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
