import { Question } from 'components/organisms/questions';
import { getQueries } from '../../components/link/LinkHelper';
import { history } from '../../store';

import type { Customizations } from '../../services/apiTypes';
import {
  getCombinedQuestions as _getCombinedQuestions,
  getTotalNrQuestions as _getTotalNrQuestions,
} from '../../reducers/pollQuestionUtils';
import { makeQualifyingQuestion } from '../questions/QuestionConstants';
import type { Polls } from '../../components/organisms/question/types';

function getTrimmedPath(location: Location): string {
  let trimmedPath = '';
  if (location.pathname) {
    const array = location.pathname.split('/');
    trimmedPath = `/${array[0]}${array[1]}/${array[2]}`;
  }
  return trimmedPath;
}

export function closePoll(customizations: Customizations | null, location: Location): void {
  if (customizations?.hideClosePollButton) {
    return;
  }
  const tergetURL: string = `${getTrimmedPath(location)}${getQueries()}`;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  history.push(tergetURL);
}

// Bridge to use the same logic as in old implementation (via Redux state reducers/poll.js)
export function getCombinedQuestions(poll: Polls | null): Question[] {
  if (!poll) {
    return [];
  }
  const qualifyingQuestion = poll.segmentationQuestion && {
    ...poll.segmentationQuestion,
    question: {
      ...poll.segmentationQuestion?.question,
      questionType: makeQualifyingQuestion(poll.segmentationQuestion?.question.questionType),
    },
  };
  return _getCombinedQuestions(
    poll?.questions || [],
    // IMPORTANT define type for PersonalData
    // [qualifyingQuestion, poll.demographicQuestion, poll.personalData],
    [qualifyingQuestion, poll.demographicQuestion],
    poll?.isConditionalPoll,
  ) as Question[];
}

export function getTotalNrQuestions(questions: Question[]): number {
  return _getTotalNrQuestions(questions) as number;
}
