import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Polls } from 'components/organisms/question/types';
import PageLoader from 'components/spinner/PageLoader';
import { retrievePollFromServer } from 'services/polls';
import { getDataFromServer as retrieveProjectAbout } from 'services/project/header';
import Survey, { SurveyFormProps } from './Survey';
import { useLocationVerification, LocationVerificationStatus } from './useLocationVerification';
import { LocationValidation } from './LocationValidation';

type About = {
  _id: string;
  location: {
    latitude: number;
    longitude: number;
  };
  projectName: string;
  projectUrl: string;
  about: string;
  architects: string;
  projectHeader: string;
  projectLogo: string;
  locationName: string;
  // locationExtended:
};

export type ApiData = {
  poll: Polls;
  about: About;
};

function announcePageLoading() {
  // Create a hidden live region
  const liveRegion = document.createElement('div');
  liveRegion.setAttribute('aria-live', 'assertive'); // Announce immediately
  liveRegion.setAttribute('role', 'alert'); // Screen reader prioritizes this announcement
  liveRegion.style.position = 'absolute';
  liveRegion.style.left = '-9999px'; // Hide visually but keep it in the DOM
  liveRegion.textContent = 'Opening survey page...'; // Message to screen reader

  // Append the live region to the body
  document.body.appendChild(liveRegion);

  // Clean up the live region after a short delay
  setTimeout(() => {
    document.body.removeChild(liveRegion);
  }, 3000); // Adjust timing as needed
}

export default function SurveyContainer(props: SurveyFormProps): JSX.Element {
  const {
    clazz,
    match: {
      params: { id: pollId },
    },
    slugId,
    // location,
    projectConfiguration,
  } = props;

  const [apiData, setApiData] = useState<ApiData | null>(null);
  const isLocationVerified = useLocationVerification(projectConfiguration?.domain?.isActivated, slugId);

  useEffect(() => {
    if (!(slugId && pollId)) return;
    announcePageLoading();

    const apiRequests = [retrieveProjectAbout(slugId), retrievePollFromServer(slugId, pollId)];

    Promise.all(apiRequests)
      .then((res: AxiosResponse<unknown>[]) => {
        // console.log('Poll:', res[0].data);
        const about = res[0].data as About;
        const poll = res[1].data as Polls;
        setApiData({ about, poll });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Error:', err);
      });
  }, [pollId, slugId]);

  return (
    <div aria-live='assertive'>
      {apiData && isLocationVerified === LocationVerificationStatus.Verified ? (
        <Survey {...props} apiData={apiData} />
      ) : null}
      <LocationValidation
        isLocationVerified={isLocationVerified}
        locationName={apiData?.about.locationName}
        clazz={clazz}
      />
      {isLocationVerified === LocationVerificationStatus.Pending ? <PageLoader /> : null}
    </div>
  );
}
