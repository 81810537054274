import { useRef, RefObject } from 'react';
import QuestionHeader from 'components/question/QuestionHeader';
import PollProgress from 'components/question/PollProgress';
import QuestionHelper from 'containers/questions/QuestionHelper';
import { useGetNextQuestion } from 'utils/hooks/useGetNextQuestion/useGetNextQuestion';
import {
  AT_1007_CHARITY,
  AT_1014_END_OF_POLL,
  AT_1013_KNOW_LOCATION,
  AT_1016_WRONG_LOCATION,
  INCLUDE_DEMOGRAPHIC,
  INCLUDE_FACTS,
  INCLUDE_QQ,
  EXCLUDE_FACTS,
  isQualifyingQuestion,
  isQuestion,
  isDemographicQuestion,
} from 'containers/questions/QuestionConstants';
import { pollQuestionSkip } from 'services/polls';

// Non-question forms
import EndOfPollAnonymous from '../../components/gamefication/EndOfPollAnonymous';
import AccessDenied from '../../components/location/AccessDenied';
import WrongLocation from '../../components/location/WrongLocation';
import EndOfPollCharity from '../../components/gamefication/EndOfPollCharity';

import type { ProjectConfiguration } from '../../services/apiTypes';
import type { Answer } from '../../components/organisms/question/types';
import { closePoll, getCombinedQuestions, getTotalNrQuestions } from './SurveyUtils';
import type { ApiData } from './SurveyContainer';

export type SurveyFormProps = {
  apiData: ApiData;
  clazz: string;
  match: {
    params: {
      id: string;
    };
  };
  slugId: string;
  location: Location;
  projectConfiguration: ProjectConfiguration;
  initialQuestion: string;
};

function Survey(props: SurveyFormProps): JSX.Element {
  const {
    apiData: { poll, about },
    clazz,
    match: {
      params: { id },
    },
    slugId,
    location,
    projectConfiguration,
    initialQuestion,
  } = props;
  // console.log('SurveyId:', id, about, poll, projectConfiguration);

  // const [poll, setPoll] = useState<Polls | null>(null);
  // const customizations: Customizations = projectConfiguration?.customizations;
  // const [customizations, setCustomizations] = useState<Customizations | null>(null);

  const questions = getCombinedQuestions(poll);
  const answers: RefObject<Answer[]> = useRef<Answer[]>([]);
  const [currentQuestionIndex, currentForm, getNextQuestion] = useGetNextQuestion(
    questions,
    poll?.lastQuestionAnswered || null,
    poll?.isConditionalPoll || false,
    initialQuestion,
    // undefined,
    // initialFormType,
    undefined,
    // getActiveCharities(charities).length > 0,
    false,
    poll?.isCharityChosen || false,
    id,
    answers,
  );

  // console.log('CurrentQuestionIndex:', currentQuestionIndex, currentForm, poll?.lastQuestionAnswered);

  // ###### DUMMIES
  function onCharityClick() {
    // console.log('Charity clicked');
  }
  function dummy() {}

  function nextPoll() {}

  // ###### END DUMMIES

  function _closePoll() {
    // console.log('ClosePoll:', projectConfiguration?.customizations, location);
    closePoll(projectConfiguration?.customizations, location);
  }

  function onEndQuestion() {
    getNextQuestion();
  }

  async function onSkipQuestion() {
    await pollQuestionSkip(slugId, poll?._id, currentQuestionIndex);
    onEndQuestion();
  }

  const _questionHeaderProps = {
    currentQuestion: 1, //
    questionCount: getTotalNrQuestions(questions),
    onSkipQuestion,
    onClosePoll: _closePoll,
    isQuestion: isQuestion(currentForm, INCLUDE_QQ, EXCLUDE_FACTS, INCLUDE_DEMOGRAPHIC),
    isQualifyingQuestion: isQualifyingQuestion(currentForm),
    isDemographicQuestion: isDemographicQuestion(currentForm),
    points: {
      //
      pointsPerQuestion: 5,
      pointsEarned: 10,
    },
    showCoins: false, //
    hideSkipDemographic: false, //
    isConditionalPoll: poll?.isConditionalPoll,
    hideClosePollButton: false, //
  };

  const _pollProgressProps = {
    questionCount: 5,
    currentStep: 1,
  };

  function getCurrentQuestion() {
    // console.log('CurrentQuestionIndex:', currentQuestionIndex, poll?.questions[currentQuestionIndex]);
    return questions[currentQuestionIndex];
  }

  function formSwitcher() {
    // if (projectConfiguration.domain.isActivated === true) {
    //   console.log('Activated');
    //   return <AccessDenied closePoll={_closePoll} />;
    // }
    const _currentQuestion = getCurrentQuestion();
    // console.log('_currentQuestion:', currentForm, currentQuestionIndex, _currentQuestion);
    const questionId = _currentQuestion?._id || 0;

    const params = {
      // Need key to trigger re-render on question change if repeated question type
      key: questionId,
      question: getCurrentQuestion(),
      slugId,
      pollId: poll?._id,
      getUpdatedPolls: dummy, // ????
      questionButtonClicked: onEndQuestion,
      showCoin: true, //
      // QQ specific
      // qType is needed because we re-use ImageQualifyingQuestions component for 3 question types
      qType: currentForm,
      isQQ: isQualifyingQuestion(currentForm),
      showCoinsInHeader: dummy, //
      setHideSkipDemographic: dummy, //
      skipResults: poll?.skipCommunityResponse, //
      setAnnotateEdit: dummy, //
      setHideSkipAfterAnswer: dummy, //
      setIsMediaZoomed: dummy, //
      isMediaZoomed: false, //
    };

    // Render questions
    if (isQuestion(currentForm, INCLUDE_QQ, INCLUDE_FACTS, INCLUDE_DEMOGRAPHIC)) {
      const questionFormType = QuestionHelper.questionSwitcher(currentForm, params) as JSX.Element | null;
      if (questionFormType) {
        return questionFormType;
      }
      getNextQuestion();
    }

    /**
     * Handling remaining non-question actions
     */
    switch (currentForm) {
      case AT_1007_CHARITY:
        return <EndOfPollCharity slugId={slugId} closePoll={closePoll} onSubmitCharity={onCharityClick} />;

      case AT_1014_END_OF_POLL:
        return <EndOfPollAnonymous pollId={poll._id} nextPollAction={nextPoll} closePoll={closePoll} slugId={slugId} />;

      case AT_1013_KNOW_LOCATION:
        return <AccessDenied closePoll={closePoll} />;

      case AT_1016_WRONG_LOCATION:
        return <WrongLocation closePoll={closePoll} location={about.locationName} />;

      default:
        return (
          <EndOfPollAnonymous
            pollId={poll._id}
            nextPollAction={nextPoll}
            closePoll={closePoll}
            slugId={slugId}
            // hasOneAnswer={answers.length > 0 || poll.lastResponse}
          />
        );
    }
  }

  return (
    <div className={clazz}>
      <main className='l-section-main c-section-main' id='appContent'>
        <div className='l-container'>
          <div className='c-modal-poll-container'>
            <div className='c-modal-poll'>
              <QuestionHeader {..._questionHeaderProps} />
              <PollProgress {..._pollProgressProps} />
              <div className='c-modal-poll__content'>{getCurrentQuestion() !== null ? formSwitcher() : null}</div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Survey;
