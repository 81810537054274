import moment from 'moment';
import { ExpirationTypes } from '../shared/engage-token-expirationEnums';

function isMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return true;
  }

  if (/android/i.test(userAgent)) {
    return true;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }

  return false;
}

const BID_TOKEN = 'BID-Authorization';

function getExpirationTime(expiryEnum) {
  const d = new Date();
  switch (expiryEnum) {
    case ExpirationTypes.D1:
      return d.setHours(24, 0, 0, 0);
    // return d.setMinutes(d.getMinutes() + 30);
    default:
      return Number.MAX_SAFE_INTEGER;
  }
}

function getParsedTokenOrMakeWithExpiry() {
  const itemStringified = localStorage.getItem(BID_TOKEN);
  if (!itemStringified) return null;
  let item = {};
  try {
    item = JSON.parse(itemStringified);
  } catch (error) {
    // console.log(error);
    localStorage.setItem(
      BID_TOKEN,
      JSON.stringify({ value: itemStringified, expirationTime: Number.MAX_SAFE_INTEGER }),
    );
    item = null;
  }
  return item;
}

function getTokenWithExpiry(key) {
  const itemStringified = localStorage.getItem(key);

  if (!itemStringified) {
    return null;
  }

  let item = {};

  try {
    item = JSON.parse(itemStringified);
  } catch (error) {
    // console.log(error);
  }

  const now = new Date();

  if (now.getTime() > item.expirationTime) {
    localStorage.removeItem(BID_TOKEN);
    return null;
  }

  return item.value;
}

function setTokenWithExpiry(key, value, expiryEnum) {
  const expirationTime = getExpirationTime(expiryEnum);
  const item = { value, expirationTime };
  localStorage.setItem(key, JSON.stringify(item));
}

function getBidTokenWithExpiry() {
  return getTokenWithExpiry(BID_TOKEN);
}

function getTokenHash() {
  const token = getBidTokenWithExpiry();

  if (!token || token === 0) return 0;

  let hash = 0;
  let i;
  let chr;

  for (i = 0; i < token.length; i += 1) {
    chr = token.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

function setBidTokenWithExpiry(value, expiryEnum) {
  setTokenWithExpiry(BID_TOKEN, value, expiryEnum);
}

function getBidTokenWithExpiryAndApplyEnum(expiryEnum) {
  const value = getBidTokenWithExpiry();
  if (getParsedTokenOrMakeWithExpiry() && getParsedTokenOrMakeWithExpiry().expirationTime === Number.MAX_SAFE_INTEGER) {
    setBidTokenWithExpiry(value, expiryEnum);
  }
  return value;
}

function getActiveCharities(charities) {
  return charities.filter((charity) => charity.isActive);
}

function isEmailValid(emailText) {
  if (!emailText) {
    return false;
  }

  const emailRegex = /^[\w._-]+[+]?([\w._-]+)?@[\w.-]+\.[a-zA-Z]{1,6}$/;
  return emailRegex.test(emailText);
}

function getDateTimeString(inDate) {
  return moment(inDate).format('DD.MM.YYYY.');
}

export {
  isMobile,
  getBidTokenWithExpiry,
  setBidTokenWithExpiry,
  getParsedTokenOrMakeWithExpiry,
  getBidTokenWithExpiryAndApplyEnum,
  getActiveCharities,
  getTokenHash,
  isEmailValid,
  getDateTimeString,
};
